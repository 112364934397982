@import "../../assets/styles/variables";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=Roboto:wght@700;900&display=swap");

.mainWrapper {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  margin: 0;
  padding: 1rem 10rem;
  display: flex;
  flex-direction: column;
  background-color: $primary-color;
  font-family: "DM Sans", sans-serif;
  background-color: #283618;
  background-image: $background-image; //alerar
  background-attachment: fixed;
  background-size: cover;
  ::-moz-selection {
    color: black;
    background: #fefae0;
  }

  ::selection {
    color: black;
    background: #fefae0;
  }

  pre {
    animation: rotateAnimation 10s linear infinite;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8));
    opacity: 0.9;
  }

  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8rem;
    position: relative;

    .modalOverlay {
      background-color: #fefae0;
      display: flex;
      justify-content: center;
      border-radius: 20px;
      border: 4px solid;
      border-color: #606c38;
      height: 70%;
      width: 40%;
      overflow-y: scroll;
      overflow-x: hidden;
      z-index: 2;
      scroll-behavior: smooth;
      scrollbar-color: red;
      position: fixed;
      top: 50%;
      left: 50%;
      padding-top: 1em;
      transform: translate(-50%, -50%);
      animation: fadeIn 0.1s ease-in-out;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      .modalContent {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 100%;
        padding-top: 1em;

        hr {
          border-radius: 50px;
          border-color: black;
          background-color: black;
          margin-top: 2em;
        }

        .closeButton {
          color: black;
          font-size: 1.5em;
          font-weight: 900;
          cursor: pointer;
          position: sticky;
          top: 5%;
          margin-left: 100%;
        }

        .modalTitle {
          color: #283618;
          font-size: 2.2em;
          font-family: DM Sans;
          font-weight: 600;
          margin-bottom: 1em;
        }

        .modalDescription {
          font-family: DM Sans;
          font-weight: 600;
          margin-bottom: 2em;
          font-size: 1.2em;
        }

        .experienceWrapper {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          margin-bottom: 2em;

          img {
            width: 200px;
            margin-bottom: 1em;
          }

          .experienceTitle {
            font-size: 2em;
            font-family: DM Sans;
            font-weight: 600;
            margin-bottom: 1em;
            color: #606c38;
          }

          .experienceDescription {
            font-size: 1em;

            strong {
              font-weight: 600;
            }
          }
        }

        p:last-child {
          margin-bottom: 2em;
        }
      }
    }
    .projectsModalOverlay {
      background-color: #fefae0;
      display: flex;
      justify-content: center;
      border-radius: 20px;
      border: 4px solid;
      border-color: #606c38;
      height: 60%;
      width: 40%;
      overflow-y: scroll;
      overflow-x: hidden;
      z-index: 2;
      scroll-behavior: smooth;
      scrollbar-color: red;
      position: fixed;
      top: 50%;
      left: 50%;
      padding-top: 1em;
      transform: translate(-50%, -50%);
      animation: fadeIn 0.1s ease-in-out;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      .modalContent {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 100%;
        padding-top: 1em;

        hr {
          border-radius: 50px;
          border-color: black;
          background-color: black;
          margin-top: 2em;
        }

        .closeButton {
          color: black;
          font-size: 1.5em;
          font-weight: 900;
          cursor: pointer;
          position: sticky;
          top: 5%;
          margin-left: 100%;
        }

        .modalTitle {
          color: #283618;
          font-size: 2.2em;
          font-family: DM Sans;
          font-weight: 600;
          margin-bottom: 1em;
        }

        .modalDescription {
          font-family: DM Sans;
          font-weight: 600;
          margin-bottom: 2em;
          font-size: 1.2em;
        }

        .experienceWrapper {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          margin-bottom: 2em;

          img {
            width: 200px;
            margin-bottom: 1em;
          }

          .experienceTitle {
            font-size: 2em;
            font-family: DM Sans;
            font-weight: 600;
            margin-bottom: 1em;
            color: #606c38;
          }

          .experienceDescription {
            font-size: 1em;

            strong {
              font-weight: 600;
            }
          }
        }

        p:last-child {
          margin-bottom: 2em;
        }
      }
    }

    .modalOverlay::-webkit-scrollbar {
      width: 0px;
    }

    .modalOverlay::-webkit-scrollbar-track {
      background-color: #fefae0;
    }

    .modalOverlay::-webkit-scrollbar-thumb {
      box-shadow: inset 0 1px 1px #fefae0;
      background: black;
    }

    .description {
      display: flex;
      flex-direction: column;
      height: fit-content;
      margin-bottom: 1.8em;
      align-items: flex-start;

      .name {
        color: $terciary-color;
        font-size: 7em;
        font-weight: 700;
        font-family: "Roboto", sans-serif;
        position: relative;
        z-index: 1;
        /* Adicionei esta linha */
      }

      .name::before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        inset: 0 0 0 0;
        background: #588157;
        z-index: -1;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease;
      }

      .name:hover::before {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      .descriptionSmall {
        font-size: 4em;
        color: white;
        font-family: "DM Sans";
      }
    }

    .resume {
      display: flex;
      flex-direction: column;
      height: fit-content;
      border-radius: 20px;
      font-size: 1.2rem;
      font-family: DM Mono, monospace;

      .resumeDescription {
        width: 50%;

        hr {
          border-color: #606c38;
        }

        p {
          color: $secondary-color;
          overflow-wrap: break-word;
          font-family: DM Sans;
          font-size: 1.4em;
          font-weight: 400;
        }

        p:last-child {
          text-align: center;
        }
      }
    }

    .badgesWrapper {
      // background-color: white;
      width: 100%;
      display: flex;
      max-height: 20%;
      padding-top: 6em;

      .badgeItem {
        display: flex;
        align-items: center;
        margin-right: 1.4em;

        .badgeIco {
          width: auto;
          height: 40px;
        }

        p {
          padding-left: 1em;
          font-size: 1.2em;
          color: $five-color;
          text-decoration: underline;
          text-underline-offset: 6px;
          line-height: 1.4rem;
        }

        .experienceModal {
          // Defina as propriedades iniciais
          cursor: pointer;
          transform: translateY(0);

          // Defina a animação
          animation: bouncing 1s infinite;
        }

        // Defina os keyframes para a animação de bouncing
        @keyframes bouncing {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }

          40% {
            transform: translateY(-10px);
          }

          60% {
            transform: translateY(-5px);
          }
        }
      }
    }

    .aboutWrapper {
      width: 100%;
      height: 30vh;
      display: flex;
      flex-direction: row;
      margin-top: 10em;

      .profileSection {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;

        img {
          border-radius: 180px;
          width: 100%;
          object-fit: cover;
        }
      }

      .aboutDescription {
        width: 80%;
        max-height: 100%;
        display: flex;
        padding: 1em 3em;

        p {
          color: $five-color;
          font-family: DM Sans;
          font-size: 1.4em;
          line-height: 2rem;
        }
      }
    }

    .projectsWrapper {
      width: 100%;
      height: fit-content;
      padding-top: 10em;

      // background-color: #070b34;
      .titleSection {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.4em;

        h1 {
          color: $five-color;
          font-size: 5em;
          font-family: DM Sans;
        }
      }

      .projectItem {
        width: 100%;
        height: fit-content;
        padding-top: 1em;
        display: flex;
        margin-bottom: 2rem;

        .sectionLeft {
          width: 50%;
          height: 100%;

          p {
            color: $secondary-color;
            font-family: DM Sans;
            font-size: 2.5em;
          }

          .projectLinks {
            width: 100%;
            height: 20%;
            display: flex;
            align-items: center;
            font-size: 0.45em;
            padding-top: 1em;
            padding-bottom: 1.5rem;

            .linkItem {
              display: flex;
              align-items: center;
              margin-right: 5em;

              img {
                width: auto;
                height: 30px;
                margin-right: 1em;
              }

              a {
                text-decoration: none;
                color: $white;
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-offset: 4px;
                font-size: 2.5em;
              }
            }
          }
        }

        .sectionRight {
          width: 50%;
          height: 100%;
          background: rgb(2, 0, 36);
          background: linear-gradient(
            191deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(86, 121, 9, 1) 34%,
            rgba(188, 193, 4, 1) 74%,
            rgba(193, 197, 4, 1) 76%,
            rgba(161, 155, 63, 1) 100%
          );
          border-radius: 15px;
          overflow: hidden;
          padding-left: 1em;

          img {
            width: 800px;
            height: 100%;
            max-height: 30rem;
            border-radius: 15px;
          }
        }

        .projectDescription {
          width: 80%;
          display: flex;
          flex-direction: column;

          p {
            font-size: 1.4em;
            color: $five-color;
            font-family: DM Sans;
            line-height: 2rem;
          }

          .projectBadges {
            width: 100%;
            height: 5vh;
            display: flex;

            .badgeItem {
              display: flex;
              align-items: center;
              padding-top: 2.6em;
              margin-right: 1.6em;

              img {
                width: 30px;
                height: 30px;
                margin-right: 0.6em;
              }

              p {
                font-size: 1.2em;
                color: $white;
              }
            }
          }
        }
      }

      hr {
        border-color: #606c38;
      }
    }

    h1 {
      color: white;
    }

    .slideWrapper {
      width: 100%;
      height: 20vh;
      background-color: #606c38;
    }
  }
}

@media (max-width: 576px) {
  .mainWrapper {
    overflow-x: hidden;
    padding: 2rem 1rem;

    .home {
      .description {
        .name {
          font-size: 5em;
          color: $terciary-color;
        }

        .name:first-child {
          font-size: 3em;
          color: white;
        }
      }

      .badgesWrapper {
        .badgeItem {
          margin-right: 0em;
        }

        .badgeItem:last-child {
          margin-left: 1em;
        }
      }

      .modalOverlay {
        width: 85%;
      }
      .projectsModalOverlay {
        width: 85%;
      }

      .aboutWrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        margin-top: 2em;

        .aboutDescription {
          p {
            text-align: center;
            line-height: 1.1em;
          }
        }

        .profileSection {
          img {
            width: 200px;
          }
        }

        .aboutDescription {
          max-height: none;
        }
      }

      .projectsWrapper {
        padding-top: 1.4em;
        height: fit-content;

        .titleSection {
          justify-content: center;
        }

        .projectItem {
          flex-direction: column;
          height: fit-content;
          align-items: center;

          .projectDescription {
            padding-top: 2em;

            .projectBadges {
              justify-content: space-around;

              .badgeItem {
                margin-right: 0;
              }
            }
          }

          .sectionLeft {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin-bottom: 2em;

            p {
              text-align: center;
            }

            .projectLinks {
              justify-content: space-around;

              .linkItem {
                margin-right: 0;
              }
            }
          }

          .sectionRight {
            width: 100%;
            padding: 0;
            border: none;
            background: none;

            img {
              width: 100%;
              max-height: 307px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .mainWrapper {
    overflow-x: hidden;
    padding: 2rem 1rem;

    .home {
      .description {
        .name {
          font-size: 3.6em;
          color: $terciary-color;
        }

        .name:first-child {
          font-size: 2.5em;
          color: white;
        }

        .descriptionSmall {
          font-size: 3em;
        }
      }

      .resume {
        .resumeDescription {
          width: 70%;
        }
      }

      .badgesWrapper {
        .badgeItem {
          p {
            font-size: 1em;
          }
        }
      }

      .aboutWrapper {
        .aboutDescription {
          width: 95%;
        }
      }

      .projectsWrapper {
        .projectItem {
          .sectionLeft {
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
