.footerWrapper {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: column;

  .footerDescription {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-family: Open Sans;
      color: #fefae0;
      margin-bottom: 1em;
    }
    .sociasIcons {
      display: flex;
      flex-direction: row;
      svg {
        margin-right: 0.6em;
      }
    }
  }
}
